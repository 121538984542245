/**
 * Dostupné mikroslužby
 */
export enum ApiServiceName {
  ApiGateWay = '',
  AccessManagement = 'access-management',
  ClientStorage = 'client-storage',
  Confluence = 'confluence',
  Construction = 'construction',
  Documents = 'documents',
  Economics = 'economics',
  Emails = 'emails',
  GlobalRecords = 'global-records',
  Identity = 'identity',
  LegacyConstruction = 'legacykonstrukce',
  Menu = 'menu',
  NotifyCenter = 'notify-center',
  PersonalData = 'personal-data',
  ServiceSheets = 'service-sheets',
  TaskManager = 'task-manager',
  Technology = 'technology/api',
  Machinery = 'machinery',
  Manufactory = 'manufactory',
  ToolsManagementCatalog = 'tools-management/catalog',
  ToolsManagementDispensing = 'tools-management/dispensing',
  ToolsManagementWarehouse = 'tools-management/warehouse',
  WorkflowManagement = 'workflow-management',
  Planning = 'planning',
  Timesheets = 'timesheets/api',
  Material = 'material/api',
  SupplierManagement = 'supplier-management/api',
  ExternalCooperation = 'external-cooperation/api',
  Invoicing = 'invoicing/api',
}
