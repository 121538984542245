import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationStart, Router } from '@angular/router';
import { AppVersionConfiguration } from '@app/shared/models/config';
import { FrontEndDataService } from '@core/api/services/api-gateway/frontend-data.service';
import { environment } from '@environments/environment';
import { Observable, filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FrontedVersionCheckService {
  private readonly _router = inject(Router);
  private readonly _frontEndDataService = inject(FrontEndDataService);

  public constructor() {
    this.init();
  }

  private init() {
    if (environment.checkVersion) {
      this._router.events
        .pipe(
          takeUntilDestroyed(),
          filter((e): e is NavigationStart => e instanceof NavigationStart),
        )
        .subscribe(() => {
          this.checkVersion();
        });
    }
  }

  private checkVersion() {
    this.loadFrontEndConfig().subscribe((config: AppVersionConfiguration) => {
      console.log(config);

      const isVersionTheSame = config.frontendVersion === environment.appVersion;
      if (config.enableCheck && !isVersionTheSame) {
        console.log('Version different. Refreshing');
        console.log(`OLD FE VERSION: ${environment.appVersion}`);
        console.log(`NEW FE VERSION: ${config.frontendVersion}`);
        window.location.reload();
      }
    });
  }

  private loadFrontEndConfig(): Observable<AppVersionConfiguration> {
    return this._frontEndDataService.getFrontEndConfig();
  }
}
