/**
 * Výčet typů nastavení komponenty
 */
export enum ComponentSettingsType {
  Filter = 'Filter',
  Columns = 'Columns',
  Paging = 'Paging',
  PersonalUserDisplaySettings = 'PersonalUserDisplaySettings',
  Avatar = 'Avatar',
}
