import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { ApiServiceName, ClientStorageController } from '@api/enums/controllers';
import { BaseApiService } from '@api/services/base-api.service';
import { ApiService } from '@api/services/api.service';
import { ComponentSettingsModel, ComponentSettingsType, PrimaryComponentSettingModel } from '@api/models/client-storage';
import { SanitizationUtility } from '@core/utils/sanitization-utility';

@Injectable({
  providedIn: 'root',
})
export class ComponentsDataService extends BaseApiService<ApiServiceName.ClientStorage, ClientStorageController.Components> {
  constructor(
    override readonly _apiService: ApiService,
    private readonly _httpClient: HttpClient,
  ) {
    super(_apiService, ApiServiceName.ClientStorage, ClientStorageController.Components);
  }

  /**
   * Načte nastavení komponenty
   * @param id Musí být jedno z IDček v client-storage-component-grid-grid-ids.ts
   * @param typeId Typ
   * @returns Nastavení komponenty
   */
  public getComponentSettings(id: string, typeId: ComponentSettingsType): Observable<ComponentSettingsModel[]> {
    return this._httpClient.get<ComponentSettingsModel[]>(`${this.baseUrl}/${id}/types/${typeId}/settings`);
  }

  /**
   * Vrátí všechny primární nastavení komponenty
   * @param id
   * @returns
   */
  public getComponentPrimarySettings(id: string): Observable<PrimaryComponentSettingModel[]> {
    return this._httpClient.get<PrimaryComponentSettingModel[]>(`${this.baseUrl}/${id}/primary-settings`).pipe(
      map((primarySettings: PrimaryComponentSettingModel[]) => {
        primarySettings.forEach(setting => {
          setting.data = SanitizationUtility.desanitize(setting.data);
        });
        return primarySettings;
      }),
    );
  }
}
