/**
 * Dostupné kontrolery mikroslužby Planning
 */
export enum PlanningController {
  ConstructionGroups = 'construction-groups',
  CostCenters = 'cost-centers',
  DeviceInstances = 'device-instances',
  BillsOfMaterial = 'bills-of-material',
  DrawingDocumentationTypes = 'drawing-documentation-types',
  RequestsForConstruction = 'requests-for-construction',
  PartsOfCarType = 'parts-of-car-type',
  Projects = 'projects',
  EconomicCenters = 'economic-centers',
  Workflows = 'workflows',
  Listbooks = 'list-books/book',
  Devices = 'devices',
  ScheduleTemplates = 'schedule-templates',
  ChangeInstitutes = 'change-institutes',
  Batches = 'batches',
  Users = 'users',
  DeadlineSchedules = 'deadline-schedules',
  OrganizationalUnits = 'organizational-units',
  BatchTrees = 'batch-trees',
  LimitTemplatesListBook = 'list-books/book/limit-templates',
  ChangeTypes = 'list-books/book/change-types',
  SharedLimits = 'shared-limits',
  Modules = 'modules',
  ChangeBreakdowns = 'change-breakdowns',
  CarTypes = 'list-books/book/car-types',
  Requests = 'requests',
  Customers = 'customers',
  Offers = 'offers',
  CommissionConstantsListBook = 'list-books/book/commission-constants',
  Orders = 'orders',
  PacketsListBook = 'list-books/book/packets',
  Locations = 'list-books/book/locations',
  DeviceKindsListBook = 'list-books/book/device-kinds',
  UserGroups = 'user-groups',
  CalculationSheets = 'calculation-sheets',
  CalculationSheetsSections = 'calculation-sheets/sections',
  SupplierOrderNumbers = 'supplier-order-numbers',
  ChangeIndexes = 'list-books/book/change-indexes',
}
