import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { ComponentsDataService } from '@api/services/client-storage/components-data.service';
import { ComponentSettingsDataService } from '@api/services/client-storage/component-settings-data.service';
import { PERSONAL_SETTINGS_ID } from '@core/const/client-storage-component-ids';
import { ComponentSettingDetailModel, ComponentSettingsModel, ComponentSettingsType } from '@api/models/client-storage';
import { SYSTEM_USER_VIEW_SETTINGS } from '@core/const/client-storage-component-settings-names';
import { CURRENCY_LOCAL_STORAGE_KEY, LOCALIZATION_LOCAL_STORAGE_KEY } from '@core/const/local-storage-keys';
import { UserViewSettingsModel } from '@api/models/personal-data';
import { GridRowSize } from '@shared/enums';

@Injectable({
  providedIn: 'root',
})
export class UserViewSettingsService {
  public userViewSettings$: Observable<UserViewSettingsModel | undefined>;
  private _userViewSettings: BehaviorSubject<UserViewSettingsModel | undefined>;

  constructor(
    private readonly _componentsDataService: ComponentsDataService,
    private readonly _componentSettingsDataService: ComponentSettingsDataService,
  ) {
    this._userViewSettings = new BehaviorSubject<UserViewSettingsModel | undefined>(undefined);
    this.userViewSettings$ = this._userViewSettings.asObservable();
  }

  public updateUserViewSettings(data: UserViewSettingsModel): void {
    this.updateGridRowSizeCssClass(data.rowSize, this._userViewSettings.value?.rowSize);
    this._userViewSettings.next(data);
  }

  public init(): Observable<ComponentSettingDetailModel | undefined> {
    return this.getUserViewSettings().pipe(
      tap((settings: ComponentSettingDetailModel | undefined) => {
        if (settings) {
          const detail = JSON.parse(settings.data);

          if (!localStorage.getItem(LOCALIZATION_LOCAL_STORAGE_KEY)) {
            localStorage.setItem(LOCALIZATION_LOCAL_STORAGE_KEY, detail.language);
          }

          if (detail?.currency && !localStorage.getItem(CURRENCY_LOCAL_STORAGE_KEY)) {
            localStorage.setItem(CURRENCY_LOCAL_STORAGE_KEY, detail.currency);
          }

          this.updateUserViewSettings(detail);
        }
      }),
    );
  }

  private getUserViewSettings(): Observable<ComponentSettingDetailModel | undefined> {
    return this._componentsDataService.getComponentSettings(PERSONAL_SETTINGS_ID, ComponentSettingsType.PersonalUserDisplaySettings).pipe(
      switchMap((data: ComponentSettingsModel[]) => {
        const componentSettings = data.find(p => p.name === SYSTEM_USER_VIEW_SETTINGS);
        if (componentSettings) {
          return this._componentSettingsDataService.getComponentSettingDetail(componentSettings.settingId);
        }

        return of(undefined);
      }),
    );
  }

  private updateGridRowSizeCssClass(newSize?: GridRowSize, oldSize?: GridRowSize): void {
    const body = document.getElementById('body');
    if (body) {
      if (oldSize) {
        body.classList.replace(this.getCssClassByRowSize(oldSize), this.getCssClassByRowSize(newSize));
      } else {
        body.classList.add(this.getCssClassByRowSize(newSize));
      }
    }
  }

  private getCssClassByRowSize(rowSize?: GridRowSize): string {
    let className = 'grid-row-size-';
    switch (rowSize) {
      case GridRowSize.ExtraSmall:
        className += 'extra-small';
        break;
      case GridRowSize.Small:
        className += 'small';
        break;
      case GridRowSize.Medium:
        className += 'medium';
        break;
      case GridRowSize.Large:
        className += 'large';
        break;
      default:
        className += 'medium';
        break;
    }

    return className;
  }
}
